export default {
  period_start: () => {
    var date = new Date()
    date.setFullYear(date.getFullYear() - 1)
    date.setMonth(0)
    date.setDate(1)
    return date
  },

  statuts_info: {
    // OK
    'ok': {
      'label': 'OK',
      'icon': 'fa fa-check ok',
      'class': 'ok',
      'tooltip': 'Déclaration présente dans les données de la CAFAT et de CalTT',
    },
    'chevauchement_cafat': {
      'label': 'Chevauchement CAFAT',
      'icon': 'fas fa-exchange-alt cafat',
      'class': '',
      'tooltip': 'Multiples Missions déclarés par une déclaration CAFAT',
    },
    'chevauchement_caltt': {
      'label': 'Chevauchement CalTT',
      'icon': 'fas fa-exchange-alt caltt',
      'class': '',
      'tooltip': 'Missions chevauché par une autre mission de CalTT',
    },

    // A Declarer
    'a_declarer': {
      'label': 'À Déclarer',
      'icon': 'fa fa-cloud-upload-alt a_declarer',
      'class': 'a_declarer',
      'tooltip': 'Déclaration à effectuer',
    },
    'patch_cafat': {
      'label': 'Patch CAFAT',
      'icon': 'fas fa-band-aid cafat',
      'class': '',
      'tooltip': 'Déclarations ajouté à cause d\'un chevauchement CAFAT',
    },
    'patch_caltt': {
      'label': 'Patch CalTT',
      'icon': 'fas fa-band-aid caltt',
      'class': '',
      'tooltip': 'Déclarations ajouté à cause d\'un chevauchement CalTT',
    },

    // A Annuler
    'a_annuler': {
      'label': 'À Annuler',
      'icon': 'fa fa-ban a_annuler',
      'class': 'a_annuler',
      'tooltip': 'Déclarations présente sur la CAFAT sans correspondence dans CalTT',
    },
    'annulation_auto': {
      'label': 'Annulation AUTO',
      'icon': 'fas fa-robot a_annuler',
      'class': '',
      'tooltip': 'Déclarations déclaré avec DPAE, qui sera annulé automatiquement',
    },
    'annulation_manuel': {
      'label': 'Annulation Manuel',
      'icon': 'fas fa-hand-paper a_annuler',
      'class': '',
      'tooltip': 'Déclarations déclaré manuellement ou via CalTT, qui doit être annulé manuellement',
    },

    // Stand By
    'en_attente': {
      'label': 'En Attente',
      'icon': 'fa fa-pause stand_by',
      'class': 'stand_by',
      'tooltip': 'Déclarations qui seront déclaré au prochaine envoi'
    },
    'multiple_open': {
      'label': 'Missions Multiples',
      'icon': 'fas fa-exchange-alt a_declarer',
      'class': '',
      'tooltip': 'Déclarations en attente car plusieurs missions sont ouvertent dans CalTT pour cet interimaire et compte côtisant',
    },
    'attente_annulation': {
      'label': 'Attente d\'Annulation',
      'icon': 'fa fa-ban a_annuler',
      'class': '',
      'tooltip': 'Déclarations en attente d\'une annulation pour cet interimaire et compte côtisant',
    },
    'none_a_declarer': {
      'label': '',
      'icon': '',
      'class': '',
      'tooltip': '',
    },
    'none_ok': {
      'label': '',
      'icon': '',
      'class': '',
      'tooltip': '',
    },
    'none': {
      'label': '',
      'icon': '',
      'class': '',
      'tooltip': '',
    },
  }
}
