/* eslint-disable */

export default [

  {
    type: "text",
    label: "Login CAFAT",
    name: "cafat_login",
    validation: "required",
    validationName: "Login CAFAT",

  },
  {
    type: "password",
    label: "Mot de Passe CAFAT",
    name: "cafat_password",
    validation: "required",
    validationName: "Le mot de passe CAFAT",
    autocomplete: "new-password",
  },

]
