/* eslint-disable */
import axios from 'axios'
import utils from '@/utils'
import store from '@/store'
import localforage from 'localforage'

//import router from '@/router'

const logError = function(error) {
  if (error.response) {
    console.log(error.response.data)
  } else if (error.message) {
    console.log(JSON.stringify(error.message), error)
  } else {
    console.log(JSON.stringify(error), error)
  }
}

/* NOTE: Listes des données récupèrées via dpae/declarations:

data
stats
  ...
metadata
  secteurs
  statuts
  from
  to
created_at
should_update
errors
  missions
  interimaires
  missions_ouvertent
statut_envoi
date_envoi
date_scrap

*/




var defaultData = {
  loaded: false,
  data: [],
  pages: [],
  current_page: 1,

  created_at: new Date(),
  date_scrap: false,
  date_envoi: false,
  statut_envoi: "a_envoyer",

  // Metadata
  metadata: {
    secteurs: [],
    statuts: [],
    from: "",
    to: "",
  },

  // Options
  options: {
    // Search Query
    search: '',

    // Pagination
    elpp: 25,

    // Filters
    filter: {
      to: new Date(),
      from: utils.period_start(), // 1/1/Y-1
      secteurs: [],
      statut_dpae: [
        'ok', 'a_declarer', 'a_annuler', 'en_attente',
      ],
      statut_extra: [
        // OK
        'none_ok', 'chevauchement_cafat', 'chevauchement_caltt',
        // A Declarer
        'none_a_declarer', 'patch_cafat', 'patch_caltt',
        // Annulation
        'annulation_auto', 'annulation_manuel',
        // Stand By
        'attente_annulation', 'multiple_open',
      ],
      origin: ['caltt', 'cafat'],
    },

    // Sort options
    sort: {
      key: 'date',
      order: -1, // 1 asc, -1 desc
    }
  },

  // Stats
  stats: {
    statut_dpae: {},
    statut_extra: {},
    origin: {},
    secteur: {},
    statuts: {
      a_annuler: {
        extras: {
          annulation_manuel: null
        }
      }
    }
  },

  errors: {
    interimaires: [],
    missions: [],
    missions_ouvertent: [],
  }
}


export default {
  state: () => {
    return JSON.parse(JSON.stringify(defaultData))
  },

  mutations: {
    reset(state) {
      // // console.log("dpae.reset")
      // Reset les donnees dans le localforage
      localforage.removeItem("created_at")
      localforage.removeItem("metadata")
      localforage.removeItem("data")
      localforage.removeItem("should_update")
      localforage.removeItem("errors")
      localforage.removeItem("stats")


      // Reset le state
      Object.entries(defaultData).forEach(([key, value]) => {
        state[key] = value
      })
    },

    updateState(state, newState) {
      // console.log("dpae.updateState")
      state.data = newState.data
      state.created_at = new Date(newState.created_at)
      state.created_at_iso = newState.created_at
      state.stats = newState.stats
      state.metadata = newState.metadata
      state.options.filter.from = state.metadata.from.slice(0, 10)
      state.options.filter.to = state.metadata.to.slice(0, 10)
      state.options.filter.secteurs = state.metadata.secteurs
      state.errors = newState.errors
      state.statut_envoi = newState.statut_envoi
      state.date_envoi = newState.date_envoi
      state.date_scrap = newState.date_scrap

      if (typeof(newState.errors.missions_ouvertent) == 'string') {
        state.errors.missions_ouvertent = JSON.parse(newState.errors.missions_ouvertent)
      } else {
        state.errors.missions_ouvertent = newState.errors.missions_ouvertent
      }
    },

    setSearchQuery(state, search_query) {
      state.options.search = search_query
    },

    setPage(state, page_number) {
      state.current_page = page_number
    },

    sentData(state) {
      state.date_envoi = new Date()
      state.statut_envoi = "envoyer"
    },

    updateCafat(state) {
      state.date_scrap = new Date()
    },

    setLoaded(state, value) {
      state.loaded = value
    },

    // Search
    setOptions(state, options) {
      state.options = JSON.parse(JSON.stringify(options))
    },

    resetOptions(state) {
      console.log("dpae/ResetOptions called")
      state.options = JSON.parse(JSON.stringify(defaultData.options))
      state.options.filter.from = state.metadata.from.slice(0, 10)
      state.options.filter.to = state.metadata.to.slice(0, 10)
      state.options.filter.secteurs = state.metadata.secteurs

    },

  },

  actions: {
    setPage({ state, getters, commit }, page_number) {
      var new_current_page = Math.max(1, Math.min(page_number, getters.pages.length))
      commit("setPage", new_current_page)
      state.current_page = new_current_page
    },

    initialize: async function({ state, commit, dispatch }) {
      // Load Data from localforage
      // console.log("dpae.initialize")
      commit("setLoaded", false)

      let storedData
      const has_stored_data = !!await localforage.getItem('created_at')

      if (has_stored_data) {
        const created_at = await localforage.getItem('created_at')
        const data = await localforage.getItem('data')
        const metadata = await localforage.getItem('metadata')
        const stats = await localforage.getItem('stats')
        const errors = await localforage.getItem('errors')
        const statut_envoi = await localforage.getItem('statut_envoi')
        const date_envoi = await localforage.getItem('date_envoi')
        const date_scrap = await localforage.getItem('date_scrap')

        storedData = {
          created_at,
          data,
          metadata,
          stats,
          errors,
          statut_envoi,
          date_envoi,
          date_scrap,
        }

        // Monte les données avant de check l'update
        commit("updateState", storedData)
        commit("setLoaded", true)

      } else {
        storedData = {}
      }

      var url
      if (storedData.created_at) {
        // console.log("sending call to with created_at:", storedData.created_at)
        url = `dpae/declarations/${storedData.created_at}/`
      } else {
        // console.log("sending call without stored data")
        url = 'dpae/declarations/'
      }

      axios
        .get(url)
        .then(response => {

          const payload = response.data.should_update ? response.data : storedData

          if (response.data.should_update) {
            // console.log("updateState")
            commit("updateState", payload)
            // console.log("saveState")
            dispatch("saveState", payload)
          }

          commit("setLoaded", true)

        })
        .catch(error => logError)
    },

    saveState({ state }, payload) {
      Object.entries(payload).forEach(([key, value], index) => {
        localforage.setItem(key, value)
      })
    },

    sendData({ state, commit, dispatch }) {
      // Si les DPAEs ont déjà été envoyé
      if (state.statut_envoi == "envoyer") {
        // Annuler l'envoi
        dispatch("notifications/notify", {
          type: "warning",
          message: "Les données ont déjà été envoyé",
          duration: 5000,
        }, {
          root: true
        })

        return
      }

      axios
        .post("dpae/send_data/")
        .then(response => {
          commit("sentData")
          dispatch("saveState", state)
          dispatch("notifications/notify", {
            type: "success",
            message: "Les données ont été envoyé à la Cafat, vérifiez vos emails. Un scrap des données de la cafat pour vérifier que les mouvements ont été pris en compte sera fait automatiquement dans 1 heure",
            duration: 5000,
          }, {
            root: true
          })
        })
        .catch(error => {
          dispatch("notifications/notify", {
            type: "danger",
            message: "Une erreur c'est produite lors de l'envoi des données vers la Cafat",
            duration: 5000,
          }, {
            root: true
          })
          logError(error)
        })
    },

    updateCafat({
      state,
      commit,
      dispatch
    }, force_scrap = false) {
      // console.log("dpae.updateCafat")
      // Vérifie si les données de la Cafat peuvent être scrap
      // ( C'est à dire, si les données ont été scrap il y a moins d'une heure)

      if ((new Date() - new Date(state.date_scrap) <= 3600000) && !force_scrap) {
        // Les données ont été scrap il y a moins d'une heure
        dispatch("notifications/notify", {
          type: "warning",
          message: "Les données de la CAFAT ont été mise à jour il y a moins d'une heure.",
          duration: 5000,
        }, {
          root: true
        })
        // refuse l'envoi des données
        return
      }

      // Envoi la demande d'update des données de la Cafat
      axios
        .post("dpae/update_cafat/")
        .then(response => {

          dispatch("notifications/notify", {
            type: "success",
            message: "Mise à jour des donnéees de la CAFAT en cours, cela peut prendre jusqu'à 5 minutes",
            duration: 5000,
          }, {
            root: true
          })
          commit("updateCafat")
          console.log("Response data from update_cafat")
          console.log(response.data)
          console.log(response)
          //dispatch("task/trackTaskProgress", {task_id:response.data.task_id, title:"Comparaison"}, {root:true})

        })
        .catch(error => {

          logError(error)
        })
    }
  },

  getters: {
    // Search
    filteredData(state) {
      /*
      Filter, Sort and Paginate state.data based on the options.[filter, sort]
      */

      let data = state.data

      // 1. Filter
      // 1.a search query
      const search_query = state.options.search.trim().toLowerCase().replace(",", "").normalize("NFD").replace(/\p{Diacritic}/gu, "")

      var filters = {
        search: (line) => {
          if (search_query) {
            return line.search_lookup.includes(search_query)
          } else {
            return true
          }
        },
        date: (line) => {
          /* Je sais pas pourquoi si je met pas +1 jr ca prend pas les dates du meme jour et si je met + 86400000ms ca me rajoute le jour d'apres */
          const to_plus_one_day = new Date(new Date(state.options.filter.to).getTime() + 82800000).toISOString()
          const from = (state.options.filter.from) ? state.options.filter.from <= line.date_iso : true
          const to = (state.options.filter.to) ? line.date_iso <= to_plus_one_day : true

          return from && to
        },
        secteur: (line) => {
          if (state.options.filter.secteurs.length == 0) {
            return true
          } else {
            return state.options.filter.secteurs.includes(line.secteur)
          }
        },
        statut_dpae: (line) => {
          if (state.options.filter.statut_dpae.length == 0) {
            return true
          } else {
            return state.options.filter.statut_dpae.includes(line.statut_dpae)
          }
        },
        statut_extra: (line) => {
          if (state.options.filter.statut_extra.length == 0) {
            return true
          } else {
            return state.options.filter.statut_extra.includes(line.statut_extra)
          }
        },
        origin: (line) => {
          if (state.options.filter.origin.length == 0) {
            return true
          } else {
            return state.options.filter.origin.includes(line.origin)
          }
        }
      }


      Object.entries(filters).forEach(([label, filter], idx) => {
        data = data.filter(filter)
      })

      return data
    },

    sortedData(state, getters) {
      /* 2. Sort
        Si aucune keys: sort by date, origin, type
        sinon:
        key, date, origin, type (assuming key != date || origin || type)
      */

      let data = getters.filteredData
      // console.log("sortedData", data )

      data = data.sort((line1, line2) => {
        const sortBy = key => state.options.sort.order * line1[key].localeCompare(line2[key])
        const dateSort = sortBy("_date_")
        const originSort = sortBy("origin")
        const cafatSort = state.options.sort.order * (line1.cafat - line2.cafat) // sortBy("_cafat_")
        const declTypeOrder = {
          EMB: "1",
          RUP: "2",
          ANN: "3"
        }
        const declTypeSort = state.options.sort.order * declTypeOrder[line1.decl_type].localeCompare(declTypeOrder[line2.decl_type])

        if (['', 'date'].includes(state.options.sort.key)) {
          return dateSort || declTypeSort
        } else {

          switch (state.options.sort.key) {
            case "decl_type":
              return declTypeSort || dateSort
              break;
            case "cafat":
              return cafatSort || dateSort || declTypeSort
              break;
            default:
              const keySort = sortBy(state.options.sort.key)
              return keySort || dateSort || declTypeSort

          }
        }
      })

      return data

    },

    pages(state, getters) {
      // console.log("dpae.pages")
      let data = getters.sortedData
      // console.log("dpae.pages.data", data)


      var pages = []

      const n_pages = Math.ceil(data.length / state.options.elpp)
      // console.log(n_pages)
      var start = 0
      var end = 0

      for (var page_i = 0; page_i < n_pages; page_i++) {
        start = page_i * state.options.elpp
        end = Math.min((page_i + 1) * state.options.elpp, data.length)
        pages.push(data.slice(start, end))
      }
      state.current_page = 1
      return pages
    },

    // DPAE

    send_disabled(state) {
      return state.statut_envoi == "envoyer"
    }
  }
}
