<template lang="html">


  <!-- AGENCE -->
  <div class="">

    <div class="columns is-centered">
      <div class="column is-10">
        <div class="">

          <p class="title is-4 has-text-centered">Agence</p>

          <FormulateForm name="formAgence" v-model="$parent.formData" :schema="schema" @validation="is_valid = !$event.hasErrors"/>

        </div>
      </div>
    </div>

    <div class="has-text-centered">
      <button v-if="is_valid" type="button" class="button is-primary" @click="check_form_agence">
        Enregistrer
      </button>
      <button v-else class="button is-danger" disabled>
        Informations Invalide
      </button>
    </div>

  </div>

</template>

<script>
import schema from "./form_agence_schema"
export default {
  data: () => {
    return {
      schema: schema,
      is_valid: false,
    }
  },
  props: {
    formData: {
      type: Object,
      required: true,
    }
  },
  methods: {
    check_form_agence () {
      if (!this.is_valid) {
        return
      }

      const fields = Object.keys(this.$parent.formData).filter( (key) => key !== 'used_refs')

      let formData = {}

      for (let field of fields) {
        formData[field] = this.$parent.formData[field]
      }
      console.log("SENT DATA", formData)
      this.$parent.submit(formData)

    },
  }

}
</script>

<style lang="css" scoped>
</style>
