<template>
  <p
    v-if="context.help"
    :id="`${context.id}-help`"
    class="help"
    v-text="context.help"
  />
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>
