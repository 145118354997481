<template lang="html">
  <div class="login">
      <p class="title is-4 has-text-centered">Identification</p>

      <div @key.enter="login">

        <div class="section">
          <FormulateForm v-model="formData" :schema="schema"
            @validation="validate($event)"/>
        </div>



        <!-- Actions -->
        <div class="field">
          <div class="control has-text-centered">
            <a @click="login" class="button is-success">S'identifier</a>
          </div>
        </div>

        <div class="field">
          <div class="control has-text-centered">
            Vous n'avez pas de compte ? <a @click="changeView">Inscrivez-vous !</a>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import schema from "@/components/home/login_schema"

export default {
  data: () => {
    return {
      showPassword: false,
      formData: {},
      schema: schema,
      is_valid: false,
      email: "",
      password: "",
    }
  },
  methods: {
    validate (event) {
      this.is_valid = !event.hasErrors
    },
    changeView () {
      this.$emit('change-view')
    },
    login () {
      //console.log("Login", this.formData)
      if (!this.is_valid) {
        return
      }
      //console.log("Valide")

      this.$store.dispatch("auth/login", this.formData)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
