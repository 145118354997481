import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'


axios.defaults.baseURL = `${window.location.origin}/api`
Vue.config.productionTip = false

/* * * * * * * * * *
 *  Vue Formulate  *
 * * * * * * * * * */

import VueFormulate from '@braid/vue-formulate'
import VueFormulateConfig from '@/configs/VueFormulateConfig'
import myLabel from '@/formulate/slots/label'
import myHelp from '@/formulate/slots/help'
import myInput from '@/formulate/input'
import myTextarea from '@/formulate/textarea'
import mySelect from '@/formulate/select'
import myRadio from '@/formulate/radio'
import myCKB from '@/formulate/checkbox'
import myFile from '@/formulate/file'
import myTel from '@/formulate/tel'
import myTag from '@/formulate/tag'
import horizontalGroup from '@/formulate/horizontal-group'
import myPassword from '@/formulate/password'


// Custom Slots
Vue.component('myLabel', myLabel)
Vue.component('myHelp', myHelp)

// Custom Inputs
Vue.component('myInput', myInput)
Vue.component('myTextarea', myTextarea)
Vue.component('mySelect', mySelect)
Vue.component('myRadio', myRadio)
Vue.component('myCKB', myCKB)
Vue.component('myFile', myFile)
Vue.component('myTel', myTel)
Vue.component('myTag', myTag)
Vue.component('horizontalGroup', horizontalGroup)
Vue.component('myPassword', myPassword)


Vue.use(VueFormulate, VueFormulateConfig)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
