<template lang="html">
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title">
          Activation requise
        </p>
        <p class="subtitle">
          Vous devez activé votre compte,
        </p>
        <p>
          Vérifiez vos emails
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
