<template lang="html">
  <div :data-type="context.type">
    <div class="control" :class="icon || iconLookup[context.type] ? 'has-icons-left' : ''">
      <input class="input" @focus="context.showHelp = true"
      :type="context.type" v-model="context.model" @blur="context.blurHandler"
      :maxlength="context.attributes.maxlength"
      />
      <span class="icon is-small is-left" v-if="Object.keys(iconLookup).includes(context.type) || icon">
        <i class="fas" :class="icon ? icon : iconLookup[context.type]"></i>
      </span>

    </div>
  </div>

</template>

<script>

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => {
    return {
      iconLookup: {
        username: 'fa-user',
        email: 'fa-envelope',
        tel: 'fa-phone',
      },

    }
  },
  mount () {

  },
  methods: {
    show (val) {
      console.log(val)
    }
  }
}
</script>

<style lang="css" scoped>

</style>
