import axios from 'axios'
import router from '@/router'

const logError = function (error) {
  if (error.response) {
    console.log(error.response.data)
  } else if (error.message) {
    console.log(JSON.stringify(error.message))
  }
  console.log(JSON.stringify(error))
}

export default {
  state: () => {
    return {
      user: {},
      isAuthenticated: false,
      token: "",

    }
  },
  mutations: {
    reset (state) {

      // Reset token
      state.user = {}
      state.token = ""
      state.isAuthenticated = false
      axios.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem("token")
      localStorage.removeItem("user")
    },
    initialize(state) {
      // console.log("auth.initialize")
      const token = localStorage.getItem("token")
      const user = JSON.parse(localStorage.getItem("user"))
      // console.log("token:", token)
      // console.log("user:", user)
      if (token && user) {
        // console.log("loaded data from localStorage")
        state.token = token
        state.user = user
        state.isAuthenticated = true
        axios.defaults.headers.common["Authorization"] = `Token ${token}`

      } else {
        // console.log("used default data")
        state.user = {}
        state.token = ""
        state.isAuthenticated = false
        axios.defaults.headers.common["Authorization"] = ""
      }
      // console.log("end of auth.initialize")
    },
    getUserData(state, token) {
      //console.log("auth.getUserData")

      state.token = token
      state.isAuthenticated = true
      localStorage.setItem("token", token)
      axios.defaults.headers.common["Authorization"] = `Token ${token}`

      axios
        .get("users/")
        .then(response => {
          const user = response.data[0]
          // console.log(user)
          localStorage.setItem("user", JSON.stringify(user))
          // console.log("user in storage:", localStorage.getItem("user"))
          state.user = user
        })
        .catch(error => {
            logError(error)
        })
    }
  },
  actions: {
    login({state, commit}, formData) {
      /*
      Login the user
      1) reset localStorage and state
      2) authenticate the user
      */

      commit("reset")

      axios
      .post("auth/token/login/", formData)
      .then(response => {
        // console.log("auth.login, response:", response)
        const token = response.data.auth_token

        commit("getUserData", token)
        router.push('/')
        .catch(error => {
          logError(error)
        })
      })
      .catch(error => {
        logError(error)
      })
    },

    loginFromToken({state, commit}, token) {
      state.token = token
      commit("getUserData", token)
      router.push('/')
    },

    logout({state, commit}) {
      /*
      Logs out the user
        1) Remove token from localStorage
        2) Remove token from state
        3) Send logout request to backend to destroy the token
      */



      axios
      .post("auth/token/logout/")
      .catch(error => {
        logError(error)
      })
      commit("reset")
      commit("dpae/reset", {root: true})
      router.push('/auth')
    },

    signup({commit, dispatch}, formData) {
      commit("reset")

      axios
      .post("auth/users/", formData)
      .then(response => {
        router.push("/verify")
      })
      .catch(error => {
        logError(error)
      })
    },
  },
  getters: {
    //...
  }
}
