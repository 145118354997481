<template>
  <div id="app">
    <Navbar/>

    <div class="page-content">
      <router-view/>
    </div>

    <Footer/>

    <BaseNotification
      :type="notificationType"
      :html="notificationHtml"
      :message="notificationMessage"
    />

  </div>
</template>

<script>
import Navbar from "@/components/navbar"
import Footer from "@/components/footer"
import BaseNotification from "@/components/BaseNotifications"
import {mapState} from 'vuex'
export default {
  components: {
    Navbar,
    Footer,
    BaseNotification,
  },
  beforeMount () {
    //console.log("app.beforeCreate")
    // console.log("Initializing AUTH")
    this.$store.commit("auth/initialize")

  },
  computed: {
    ...mapState({
      notificationType: state => state.notifications.type,
      notificationHtml: state => state.notifications.html,
      notificationMessage: state => state.notifications.message,
    })
  }
}
</script>

<style lang="scss">
@import 'bulma';

  #app {
    background: #F8F8F8;

  }

  .page-content {
    min-height: 100vh;
  }
</style>
