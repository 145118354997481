<template lang="html">
  <div :data-type="context.type">
    <div class="field is-grouped">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
    }
  }

}
</script>

<style lang="css" scoped>

</style>
