<template lang="html">
  <div class="columns is-centered">

  </div>
</template>

<script>
export default {
  data: () => {
    return {
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>

<style lang="css" scoped>
</style>
