/* eslint-disable */

export default [
  {
    type: "text",
    label: "Nom",
    name: "name",
    validation: "required",
    validationName: "Le nom de l'entreprise"
  },
  {
    type: "select",
    options: [
      {value: "AE", label: "AE, Auto-Entreprise"},
      {value: "EI", label: "EI, Entreprise Individuelle"},
      {value: "EIRL", label: "EIRL, Entreprise Individuelle à Responsabilité Limitée"},
      {value: "EURL", label: "EURL, Entreprise Unipersonnelle à Responsabilité Limitée"},
      {value: "SASU", label: "SASU, Société par Actions Simplifiée Unipersonnelle"},
      {value: "SARL", label: "SARL, Société A Responsabilité Limitée"},
      {value: "SAS", label: "SAS, Société par Actions Simplifiée"},
      {value: "SA", label: "SA, Société Anonyme"},
      {value: "SANC", label: "SAS, Société en Nom Collectif"},
      {value: "SEL", label: "SEL, Société d’Exercice Libéral"},
    ],
    label: "Code juridique",
    name: "code_juridique",
    validation: "^required",
    validationName: "Le code juridique",
  },
  {
    type: "text",
    label: "Code NAF",
    name: "code_naf",
    validation: "^required|matches:/^[0-9]{3,4}[a-zA-Z]$/",
    validationName: "Le code naf",
    "validation-messages": {
      "matches": "Le code naf doit commencer par 3 ou 4 chiffres et finir par une lettre",
    },

  },
  {
    type: "text",
    label: "Domaine des emails de l'entreprise",
    name: "email_domain",
    validation: "^required|domain",
    validationName: "Le domaine",
    "validation-messages": {
      "domain": "Le domaine de l'agence n'est pas valide",
    }
  },
  {
    type: "horizontalGroup",
    class: "columns",
    children: [
      {
        type: "text",
        label: "Rid",
        name: "rid",
        "outer-class": "",
        class: "control is-expanded column is-7",
        validation: "^required|^number|^max:7,length",
        validationName: "Le Rid",
        maxlength: 7,
        help: "Les 7 premiers chiffres du RIDET",

      },
      {
        type: "text",
        label: "Et",
        name: "et",
        "outer-class": "",
        class: "control column is-5",
        validation: "^required|^number|^min:3,length|^max:3,length",
        validationName: "Le Et",
        maxlength: 3,
        help: "Les 3 derniers chiffres du RIDET"
      }
    ]
  },
  {
    type: "email",
    label: "Email Emetteur",
    name: "email_emetteur",
    validation: "required|email",
    validationName: "L'email emetteur",
    help: "Email qui reçoit les emails de rapport de traitement de la CAFAT"
  },
  {
    type: "text",
    label: "Identifiant CAFAT",
    name: "cafat_identifiant",
    validation: "required",
    validationName: "L'identifiant CAFAT",
    help: "Identifiant donnée par la CAFAT, trouvable dans CalTT",
  }

]
