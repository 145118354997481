<template lang="html">
  <div>



    <!-- Statut -->
    <div class="has-text-centered block">
      <p class="title is-3">Statuts</p>

      <div class="columns is-centered">

        <div v-for="[statut_dpae, subs], idx in Object.entries(stats.statuts)" :key="idx" class="column is-size-5">
          <p :title="statuts_info[statut_dpae].tooltip">
            <i :class="statuts_info[statut_dpae].icon"></i>
            <strong :class="statuts_info[statut_dpae].class"> {{statuts_info[statut_dpae].label}}:</strong>
          </p>
          <p>
            {{subs.value}}/{{sum}}
            -
            <strong>{{percent(subs.value)}}</strong>
          </p>
          <br>

          <div v-for="[statut_extra, value], idx2 in Object.entries(subs.extras)"
          :key="idx2"
          class="is-size-6 is-clickable"
          @click="print(statut_extra)"
          :title="statuts_info[statut_extra].tooltip"
          >
            <i :class="statuts_info[statut_extra].icon"></i>
            <span class="has-text-weight-semibold">
              {{statuts_info[statut_extra].label}}
              <span class="has-text-weight-light">
                {{value}}/{{subs.value}}
              </span>
            </span>
            <br>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'
import statuts_info from '@/components/dpae/statuts_info'


export default {
  name: "Stats",
  data: () => {
    return {
      statuts_info,
    }
  },
  computed: {
    ...mapGetters({
      send_disabled: 'dpae/send_disabled',
    }),
    ...mapState({
      stats: state => state.dpae.stats,
    }),


    // Stats
    sum() {
      var sum = 0
      const sumObj = this.$store.state.dpae.stats.statut_dpae
      //console.log("sumObj", sumObj)
      Object.values(sumObj).forEach((value) => {
        sum += value
      })
      return sum
    },


  },
  methods: {

    percent(datum) {
      return `${(datum / this.sum * 100).toFixed(2)}%`
    },
  }
}
</script>

<style lang="scss" scoped>
// Note : $ok, $a_declarer, $a_annuler et $en_cours sont déclaré dans @/scss/_variables.scss
// Et pour changer les couleurs du doughnut sont dans chart-data.js

// .chart {
//   width: 400px;
//   height: 400px;
// }

.main-title {
    margin-bottom: 75px;
}

.modal {
    position: fixed;
}

.btn-icon {
    margin-right: 5pt;
}

// .offset-right {
//   transform: translateX(40px);
// }
</style>
