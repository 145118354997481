<template lang="html">
  <div class="BaseNotification">
    <div v-if="show" class="notification" :class="`is-${type}`" disabled>
      <button class="delete" @click="close"></button>
      <!-- String message -->
      <div v-if="message.length > 1" class="has-text-centered">
        <i class="fa-lg" :class="NOTIFICATION_ICONS[type]"></i> {{message}}
      </div>

      <!-- HTML Notification -->
      <div v-else-if="html.length > 1" v-html="html" class="has-text-centered"></div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
const NOTIFICATION_TYPES = {
  default: 'primary',
  info: 'info',
  link: 'link',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
}

const NOTIFICATION_ICONS = {
  default: '',
  info: 'fas fa-info-circle',
  link: 'fas fa-external-link-alt',
  success: 'fas fa-check-circle',
  warning: 'fas fa-exclamation-circle',
  danger: 'fas fa-exclamation-triangle',
}

export default {
  name: "Notification",
  components: {
  },
  props: {
    type: {
      type: String,
      required: false,
      default: function () {
        return NOTIFICATION_TYPES.default
      },
      validator: function(value) {
        // Check si la valeur est inclus dans les types de notifications
        return Object.values(NOTIFICATION_TYPES).includes(value)
      }
    },

    message: {
      type: String,
      required: false,
      default: function () {
        return false
      }
    },

    html: {
      type: String,
      required: false,
      default: function () {
        return false
      }
    }
  },
  data : () => {
    return {
      NOTIFICATION_TYPES,
      NOTIFICATION_ICONS,
    }
  },
  methods: {
    close() {
      this.$store.commit("notifications/hide")
    }
  },
  computed: {
    ...mapState({
      show: state => state.notifications.show
    })
  }
}
</script>

<style lang="scss" scoped>
.BaseNotification {
  position: fixed;
  top: 150px;
  left: 10%;
  //transform: translateX(50%);


  width: 80%;
  & .notification {
    width: 100%;
  }


}
</style>
