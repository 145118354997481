<template lang="html">
    <form @keyup.enter="submit" class="signup">

      <p class="title is-4 has-text-centered">Inscription</p>

      <div class="section">
        <FormulateForm v-model="formData" :schema="schema"
        @validation="is_valid = !$event.hasErrors"/>
      </div>


      <!-- Actions -->
      <div class="field">
        <div class="control has-text-centered">
          <a @click="submit" class="button is-success">S'inscrire</a>
        </div>
      </div>

      <div class="field">
        <div class="control has-text-centered">
          Déjà inscrit ? <a @click="changeView">Identifiez-vous !</a>
        </div>
      </div>

    </form>
</template>

<script>
import schema from "@/components/home/signup_schema"

export default {
  data: () => {
    return {
      // username: "",
      formData: {},
      is_valid: false,
      schema: schema,
      email: "",
      password: "",
      confirm_password: "",
      showPassword: false,
      showConfirm: false,
      errors: []
    }
  },
  methods: {
    changeView() {
      this.$emit('change-view')
    },
    submit() {
      console.log("SIGNUP")
      if (this.password.trim() == this.confirm_password.trim()) {
        this.signup()
      } else {
        console.log("Les mots de passe ne correspondent pas.")
      }
    },
    signup () {
      if (!this.is_valid) {
        return
      }

      this.$store.dispatch("auth/signup", this.formData)

    }
  }
}
</script>

<style lang="css" scoped>
</style>
