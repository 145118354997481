<template lang="html">
  <section class="hero is-fullheight" :class="active_state.bgClass">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title">
          {{active_state.title}}
        </p>
        <p class="subtitle">
          {{active_state.message}}
        </p>
        <p>
          <span class="icon">
            <i :class="active_state.icon"></i>
          </span>
        </p>
        <div>
          <p>Debug:</p>
          <code>uid: {{$route.params.uid}}, token: {{$route.params.token}}</code>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import router from '@/router'



const ACTIVATION_STATES = {
  init : {
    value: "init",
    title: "Activation du compte",
    icon: "spin fas fa-spinner",
    message: "Nous vérifions votre compte",
    bgClass: "is-primary",
  },

  success: {
    value: "success",
    title: "Activation réussi",
    icon: "fas fa-check",
    message: "Vous allez être redirigé",
    bgClass: "is-success",
    iconClass: "fa fas-spinner",
  },

  error: {
    value: "error",
    title: "",
    icon: "fas fa-exclamation-triangle",
    message: "",
    bgClass: "is-danger",
    iconClass: "spin fa fas-spinner",
  },
}

const redirect_after = function(route, delay=1500) {
  new Promise((response) => setTimeout(response, delay))
  .then(r => {
    router.push(route)
  })
}

export default {
  data: () => {
    return {
      active_state: ACTIVATION_STATES.init,
      states: ACTIVATION_STATES,
    }
  },
  mounted () {
    const payload = {
      uid: this.$route.params.uid,
      token: this.$route.params.token,
    }

    axios
    .post("/auth/users/activation/", payload)
    .then(response => {
      if (response.status == 204) {

        // L'utilisateur a été activé dans la Backend
        this.active_state = this.states.success

        // Identifier l'utilisateur avec le token
        const token = response.data.auth_token

        this.$store.dispatch("auth/loginFromToken", token)
      }
      else {
        console.warn("La requête est passé mais n'a pas retourné le code 204")
        console.warn(response)
      }
    })
    .catch(error => {
      this.active_state = this.states.error

      switch (error.response.status) {
        case 400:
          // Bad Request
          // Action: Renvoyer le mail d'activation
          console.error("400 - Mauvaise Requête")
          console.error(error)
          this.active_state.title = "400 - Mauvaise Requête"
          this.active_state.message = "Réenvoyer l'email de vérification"
          //redirect_after()

          break;
        case 401:
          // Invalid token / Unauthorized
          // Action: Renvoyer le mail d'activation
          console.error("401 - Token invalide")
          console.error(error)
          this.active_state.title = "401 - Jeton d'Authentification Invalide"
          this.active_state.message = "Réenvoyer l'email de vérification"
          break;
        case 403:
          // Already activated
          // Action: Rediriger vers la page d'accueil
          console.warn("403 - Le token est déjà activé")
          this.active_state.title = "401 - Jeton d'Authentification Invalide"
          this.active_state.message = "Ce jeton à déjà été activé, vous allez être redirigé pour vous identifiez et accèder au site"
          break;
        case 404:
          // Something terrible happened
          console.error("404 - Le serveur est offline")
          console.error(error)
          break;

        default:
          // Bah la...
          console.error("Une erreur est survenue lors de l'activation")
          console.error(error)

      }
      console.log("ERROR")
      console.warn(error.response.status)
    })

  },
}
</script>

<style lang="css" scoped>
</style>
