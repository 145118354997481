<template lang="html">
  <table class="table is-bordered is-striped is-hoverable is-fullwidth">
    <thead>
      <tr>
        <th v-for="key, i in keys" :key="i" class="keys"
        :class="['secteur', 'type', 'statut', 'extra', 'origin', 'secteur', 'statut_dpae', 'statut_extra'].includes(key) ? 'is-narrow' : ''">
          {{key.replace('_', ' ')}}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="line, idx in data" :key="idx" :class="options.highlight.condition(line) ? options.highlight.class: ''">

          <td v-for="key, i in keys" :key="i" :class="key.includes('statut') ? 'has-text-centered' : ''">
            <span v-if="key.includes('statut')">
              <i :class="statuts_info[line[key]].icon" :title="statuts_info[line[key]].label"></i>
            </span>
            <span v-else>
              {{line[key]}}
            </span>
          </td>

      </tr>
    </tbody>
  </table>
</template>

<script>
import statuts_info from "@/components/dpae/statuts_info"

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    cols: {
      type: Array,
      required: false,
      default: function () {
        return []
      },
    },
    options: {
      type: Object,
      required: false,
      default: function () {
        return {
          highlight: {
            condition: (el) => false,
            class: "",
          }
        }
      }
    }
  },
  data: () => {
    return {
      statuts_info,
    }
  },
  computed: {
    keys () {
      var columns = []
      if (this.cols.length > 0) {
        columns = this.cols
      }
      else {
        columns = Object.keys(this.data[0])
      }
      return columns
    }
  }

}
</script>

<style lang="css" scoped>
.keys {
  text-transform: capitalize;
}
</style>
