<template lang="html">
  <div class="">
    <table class="table is-bordered is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th @click="sortByKey(key.value)" v-for="key,i in keys" :key="i" :class="['secteur', 'type', 'statut', 'extra', 'origin', 'secteur'].includes(key.value) ? 'is-narrow' : ''">
            <span class="icon is-left">
              <i class="fas" :class="(options.sort.key === key.value ? (options.sort.order > 0 ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort has-text-grey-lighter')"></i>
            </span>
            {{ key.label }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="line, id in pages[current_page-1]" :key="id" class="lines">

          <td :class="line.origin" class="is-uppercase has-text-weight-semibold has-text-centered">{{line.origin}}</td>
          <td class="is-uppercase has-text-weight-semibold has-text-centered">{{line.secteur}}</td>
          <td>{{line.cafat}}</td>
          <td>{{line.nom}}</td>
          <td>{{line.decl_type}}</td>
          <td>{{line.date}}</td>
          <td>{{line.mission}}</td>
          <td class="has-text-centered"><i :class="statuts_info[line.statut_dpae].icon" :title="statuts_info[line.statut_dpae].label"></i></td>
          <td class="has-text-centered"><i :class="statuts_info[line.statut_extra].icon" :title="statuts_info[line.statut_extra].label"></i></td>
        </tr>
      </tbody>

    </table>

    <!-- Pagination -->
    <nav class="pagination is-centered" role="navigation" aria-label="pagination">
      <a class="pagination-previous" @click="setPage(current_page-1)">Précédent</a>
      <a class="pagination-next" @click="setPage(current_page+1)">Suivant</a>
      <ul class="pagination-list">
        <!-- Start [first] ... -->
        <li v-if="current_page > 2" @click="setPage(1)">
          <a class="pagination-link">1</a>
        </li>

        <li v-if="current_page > 2">
          <span class="pagination-ellipsis">&hellip;</span>
        </li>

        <!-- Middle [P-1] [P] [P+1] -->
        <li v-for="page, idx in page_slice" :key="idx" @click="setPage(page)"><a class="pagination-link" :class="current_page == page ? 'is-current' : ''" >{{page}}</a></li>

        <!-- End ... [last] -->
        <li v-if="current_page < pages.length - 1">
          <span class="pagination-ellipsis">&hellip;</span>
        </li>

        <li v-if="current_page < pages.length - 1" @click="setPage(pages.length)">
          <a class="pagination-link">{{pages.length}}</a>
        </li>
      </ul>
    </nav>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import utils from '@/utils'

export default {
  data: () => {
    return {
      statuts_info: utils.statuts_info,
      keys: [
        {value: 'origin', label: 'Origine'},
        {value: 'secteur', label: 'Secteur'},
        {value: 'cafat',   label: 'Cafat'},
        {value: 'nom',     label: 'Nom'},
        {value: 'decl_type',    label: 'Type'},
        {value: 'date',    label: 'Date'},
        {value: 'mission', label: 'Mission'},
        {value: 'statut_dpae',  label: 'Statut'},
        {value: 'statut_extra', label: 'Extra'}
      ],

    }
  },
  beforeMount () {

  },
  mounted () {

  },
  methods: {
    ...mapActions({
      setPage: 'dpae/setPage'
    }),
    sortByKey(key) {
      if (key == this.$store.state.dpae.options.sort.key) {
        this.$store.state.dpae.options.sort.order *= -1
      }
      else {
        this.$store.state.dpae.options.sort.key = key
      }
    },
    // setPage(idx) {
    //   console.log("setPage called")
    //   const page_number = Math.max(1, Math.min(idx, this.pages.length))
    //   // this.current_page = page_number
    //   this.$store.commit("dpae/setCurrentPage", page_number)
    // }
  },
  computed: {
    ...mapGetters({
      pages: "dpae/pages",
    }),
    ...mapState({
      options: state => state.dpae.options,
      current_page: state => state.dpae.current_page,

    }),
    page_slice () {
      return [...Array(3).keys()].map((idx) => idx + this.current_page - 1).filter( (idx) => idx > 0 && idx <= this.pages.length)
    },
  }
}
</script>

<style lang="scss" scoped>
tbody {
  user-select: text;
}

</style>
