<template lang="html">
  <div class="section">
    <div class="box section container">
      <div class="columns is-centered">
        <div class="column is-10">
          <h1 class="title is-size-2 has-text-centered">Comment ça marche</h1>

          <!-- Part I: Réglage et Installation -->
          <div class="block">
            <h2 class="title is-size-3">I. Installation et Réglages</h2>

            <!-- 1. S'inscrire -->
            <div class="block content">
              <h3 class="title is-size-4">1. S'inscrire</h3>

              <p>
                Avec votre adresse email professionnel inscrivez vous <a href="/auth">ici</a>.
              </p>

              <p>
                Note: votre email doit finir par l'un des domaines suivant:
              </p>

              <ul>
                <li>manpower.nc</li>
                <li>atoutplus.nc</li>
                <li>partnersinterim.nc</li>
              </ul>

              <p>
                Une fois inscrit vous recevrez un email de vérification, cliquez
                sur le lien dans le mail afin d'activer votre compte
              </p>
            </div>


            <!-- 2. Informations d'agence -->
            <div class="block content">
              <h3 class="title is-size-4">2. Informations de votre Agence</h3>

              <p>
                Si vous êtes la première personne à vous inscrire pour votre agence,
                vous serez redirigé sur la page <a href="/agence">Espace Agence</a>
                où vous devrez entrer les informations relatives à votre Agence.
              </p>

              <p>
                Sinon vous serez redirigé vers la page principale de <a href="/">l'état des déclarations</a>.
              </p>
            </div>

            <!-- 3. Téléchargez CalTT Data Extractor -->
            <div class="block content">
              <h3 class="title is-size-4">3. Téléchargez CalTT Data Extractor</h3>

              <p>
                Afin de récupèrer les informations de CalTT nécessaire à la déclaration
                des DPAEs, vous devez téléchargez l'extracteur de données <a href="/download">ici</a>
              </p>

              <p>
                Cet utilitaire permet l'envoi des données de CalTT jusqu'au serveur,
                mais aussi l'extraction des données de CalTT aux formats <code>.excel</code> et <code>.csv</code>
              </p>

              <p>
                Dans l'extracteur vous trouverez les instructions pour le configurer.
              </p>
            </div>
          </div>

          <!-- II. Utilisation -->
          <div class="block">

            <h2 class="title is-size-3">II. Utilisation</h2>

            <!-- 1. Contrôle -->
            <div class="block content">
              <h3 class="title is-size-4">1. Etat des déclarations: Contrôle &amp; Actions</h3>

              <p>
                Sur la page principale <a href="/">Etat des déclarations</a>
                vous trouverez les informations sur vos déclarations. Vous aurez
                aussi accès aux actions:
              </p>

              <ul>
                <li>Envoyer les Dpae</li>
                <li>Récupèrer les déclarations de la CAFAT</li>
              </ul>

              <p>
                Vous aurez aussi accès à une barre de recherche qui vous
                permettera de rechercher par Numéro Cafat, Nom, Prénom ou numéro
                de contrat les déclarations.
              </p>
            </div>

            <!-- 2. Rapport -->
            <div class="block content">
              <h3 class="title is-size-4">2. Rapport: Informations complèmentaires</h3>

              <p>
                Sur la page du <a href="/rapport">Rapport</a>, vous aurez accès
                à diverse statistiques, ainsi qu'a la liste d'erreur d'upload
                pour les intérimaires et missions, ce sont les erreurs qui
                proviennent de CalTT, intérimaires sans numéro cafat ou missions
                sans code rome, etc.
              </p>

              <p>
                Vous trouverez aussi diverses listes qui vous aideront, par
                exemple les annulations manuelles, les missions ouvertent, etc.
              </p>
            </div>
          </div>


          <!-- III. Fonctionnement -->
          <div class="block">

            <h2 class="title is-size-3">III. Fonctionnement</h2>

            <!-- 1. Récupèration des données -->
            <div class="block content">
              <h3 class="title is-size-4">1. Récupèration des données nécessaire aux déclarations</h3>

              <h4 class="title is-size-5">CalTT</h4>
              <p>
                Les données de CalTT sont récupèrées via l'extracteur de données,
                pour ce faire, il vous faudra vous connectez avec vos identifiants DPAE,
                sélectionner le dossier où se trouve CalTT et cliquez sur <strong>envoi manuel</strong>.
              </p>
              <p>
                Si vous cochez la case <strong>Envoi Automatique</strong>, les données
                seront envoyé au démarrage de l'application puis à 7h, 12h, et 17h
              </p>

              <h4 class="title is-size-5">CAFAT</h4>
              <p>
                La réception sur le serveur des données envoyées par l'extracteur de données,
                déclenche la récupèration des données de la Cafat. Cet actions peut aussi
                être déclenché manuellement sur la page de l'<a href="/">état des déclarations</a>
              </p>


              <!-- ATTENTION -->
              <p class="has-text-danger">
                <strong class="has-text-danger">Attention</strong>: il arrive que parfois  qu'en fonction de l'état du réseau,
                ou du site de la Cafat, la récupèration des données de la Cafat rate
                une page. Et donc toutes les déclarations présentent sur la page
                manqué seront considéré comme <strong class="has-text-danger">À Déclarer</strong>.
              </p>

              <p class="has-text-danger">
                En général les déclarations manqués seront pour une période incohérente,
                par exemple il manquera des déclarations pour Août 2021, alors que
                nous sommes en 2022. Pour éviter d'envoyer de mauvaise déclarations
                à la Cafat, avant d'envoyer les données, il est conseillé de jeter
                un coup d'oeil au date des missions à déclarer. Si vous trouvez une
                anomalies, la solution consiste à relancer la récupèration des données
                de la Cafat via le bouton <strong class="has-text-danger">Mettre à Jour</strong> sur la page
                de l'<a href="/">état des déclarations</a>.
              </p>

              <p class="has-text-danger">
                Après dans le pire des cas, si éventuelement des déclarations sont
                envoyées alors qu'elles sont déjà déclarés. Celle-ci seront refusées
                et le reste du fichier peut passer. Si il ne passe pas, au prochain
                envoi ces erreurs seront corrigés automatiquement.
              </p>

            </div>

            <!-- 2. Comparaison -->
            <h3 class="title is-size-4">2. Comparaison</h3>

            <div class="block content">
              <p>
                Ces données sont ensuite comparées afin d'établir un état des déclarations. Les déclarations peuvent avoir 4 statuts et 8 statuts-extra:
              </p>

              <ul>

                <!-- OK -->
                <li>
                  <strong>OK</strong>: Ce sont les déclarations présentent sur la Cafat et dans CalTT.

                  <ul>
                    <li>
                      <strong>Chevauchement Cafat</strong>: Déclarations qui ne sont pas à déclarer car elles sont chevauchées sur la Cafat.
                    </li>
                    <li>
                      <strong>Chevauchement CalTT</strong>: Déclarations qui ne sont pas à déclarer car elles sont chevauchées par d'autres missoins sur CalTT.
                    </li>
                  </ul>
                </li>

                <!-- A Declarer -->
                <li>
                  <strong>A Declarer</strong>: Ce sont les déclarations présentent seulement dans CalTT.

                  <ul>
                    <li>
                      <strong>Patch Cafat</strong>: Pseudo-déclarations sans correspondance dans CalTT ajouté pour régulariser des chevauchements Cafat.
                    </li>
                    <li>
                      <strong>Patch CalTT</strong>: Pseudo-déclarations sans correspondance dans CalTT ajouté pour régulariser des chevauchements CalTT.
                    </li>
                  </ul>

                </li>

                <!-- A Annuler -->
                <li>
                  <strong>A Annuler</strong>: Ce sont les déclarations présentent sur la Cafat mais pas dans CalTT.

                  <ul>
                    <li>
                      <strong>Annulation Manuel</strong>: Déclarations à annuler manuellement (Pour les déclarations envoyé via CalTT ou Manuellement).
                    </li>
                    <li>
                      <strong>Annulation Automatique</strong>: Déclarations qui seront annulées automatiquement.
                    </li>
                  </ul>
                </li>

                <!-- Stand-By / En Attente -->
                <li>
                  <strong>En attente</strong>: Déclarations à déclarer mais qui sont en attente.

                  <ul>
                    <li>
                      <strong>Missions Multiples</strong>: Lorsqu'un intérimaire a plusieurs missions d'ouvertent, seul la première mission est déclaré.
                    </li>
                    <li>
                      <strong>Attente d'Annulation</strong>: Déclarations d'un intérimaire qui a des déclarations en cours d'annulation.
                    </li>
                  </ul>
                </li>

              </ul>

            </div>

            <!-- 3. Envoi des Données -->
            <div class="block content">
              <h3 class="title is-size-4">3. Envoi des Données</h3>

              <p>
                Les données sont envoyées via le bouton <code>Envoyer</code>,
                présent sur la page d'<a href="/">Etat des déclarations</a>.
                Seules les données <strong>À Déclarer</strong> et les
                <strong>Annulations Automatique</strong> sont envoyées au format
                EDI sur le ftp de la Cafat.
              </p>

              <p>
                Notez que le logiciel envoi un fichier EDI par Intérimaire et par
                Secteur d'activité, afin d'éviter qu'une erreur dans une déclarations
                n'empêche l'envoi des autres déclarations.
              </p>

              <p>
                Une récupèration automatique des données de la Cafat est prévu
                une heure après l'envoi, afin de vérifier que les données envoyées
                ont bien été envoyé.
              </p>

              <p>
                Les emails d'accusès de receptions et les rapports de traitements
                sont envoyé par la Cafat sur l'email emetteur renseigné dans les
                informations de l'agence.
              </p>

              <p>
                Notez que comme le logiciel envoi un fichier EDI par intérimaire
                et par secteur, cela engendre un nombre important d'emails. Je vous
                conseil de mettre en place des règles de tri automatique.
              </p>

              <p>Règles:</p>
              <ul>
                <li>
                  Tous les emails dont le sujet comporte
                  <code>"CAFAT: Accusé de dépôt fichier"</code> doivent être supprimés.
                </li>
                <li>
                  Tous les emails dont le sujet comporte
                  <code>"CAFAT: Rapport de traitement du fichier"</code> doivent
                  être envoyés dans un dossier <strong>Rapport DPAE</strong>.
                </li>
              </ul>

              <p>Et les emails des anomalies arriveront dans votre boîte mail normal</p>

              <p>
                Cela permet d'assez facilement trouver les erreurs lors des envois.
                Je vous conseil également de vider votre dossier <strong>Rapport DPAE</strong>
                régulièrement (toutes les semaines), la Cafat envoi les rapports de
                traitement au format .pdf, qui fait 100kb, fois le nombre d'intérimaires déclarés,
                cela peut remplir assez vite votre boîte email. Ces rapports ne sont vraiment
                utiles que pour vérifier des erreurs éventuels, et perdent en utilités
                assez vite. D'expérience, il est inutile de garder les rapports antérieur à une semaine.
              </p>
            </div>

          </div>


          <!-- Conclusion -->
          <div class="block content">

            <h2 class="title is-size-3">Conclusion</h2>

            <p>
              Cet outil n'automatise pas encore à 100% les DPAEs, il manque encore
              2 élements:
            </p>

            <ol>
              <li>Lancement l'extracteur au démarrage de votre poste</li>
              <li>Validation de la récupèration des données</li>
            </ol>

            <p>
              Avec ces deux points, en théorie après les déclarations DPAE pourront
              être automatisés à 100%, c'est à dire 0 clicks. Aujourd'hui techniquement c'est 3 ou 4 cliques !
            </p>

            <p>
              Si vous avez la moindre question sur le fonctionnement du logiciel, n'hésitez pas
              à me contacter par email sur <a href="mailto:yoann.nouveau@partnersinterim.nc">yoann.nouveau@partnersinterim.nc</a>
              ou appellez moi au <a href="tel:758050">75.80.50</a>.
            </p>

            <p>
              J'espère que cet utilitaire vous simplifiera la vie, c'est le début d'une série de changement !
            </p>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data: () => {
    return {

    }

  },
  computed: {

  },
  methods: {

  },

};
</script>

<style lang="scss" scoped>


</style>
