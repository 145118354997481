import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import ModuleAuth from './modules/auth'
import ModuleDPAE from './modules/dpae'
import ModuleNotifications from './modules/notifications'
import ModuleAgence from './modules/agence'
// import ModuleTask from './modules/task'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: {
      namespaced: true,
      ...ModuleAuth
    },
    dpae: {
      namespaced: true,
      ...ModuleDPAE
    },
    agence: {
      namespaced: true,
      ...ModuleAgence
    },
    notifications: {
      namespaced: true,
      ...ModuleNotifications
    },
    // task: {
    //   namespaced: true,
    //   ...ModuleTask
    // }
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'agence' ], // Liste des modules qui doivent être persistés
    })
  ],
})
