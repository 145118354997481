<template lang="html">
  <!-- Actions -->
  <div class="has-text-centered block">
    <p class="title is-3">Actions</p>

    <div class="columns">

      <!-- Send, and Scrap -->
      <div class="column is-4 has-text-centered">

        <!-- Action: Envoyer les déclarations A Déclarer -->
        <p class="title is-5">Envoyer les déclarations</p>
        <p class="subtitle is-6">{{message_envoi}} </p>
        <div class="control">
          <a class="button is-primary" @click="sendData" :disabled="send_disabled"><i class="fa fa-cloud-upload-alt btn-icon"> </i>Envoyer</a>
        </div>
      </div>

      <div class="column is-4 has-text-centered">
        <!-- Action: Récupèrer les données de la CAFAT -->
        <p class="title is-5">Mettre à jour les données de la CAFAT</p>
        <p class="subtitle is-6">{{message_scrap}}</p>
          <div class="control">
            <a class="button is-primary" @click="_updateCafat" :disabled="scrap_disabled"><i class="fas fa-sync-alt btn-icon"> </i>Mettre à jour</a>
          </div>
          <div class="control">
            <label for="force_scrap" class="">Forcer le scrap
              <input type="checkbox" class="checkbox" v-model="force_scrap" name="force_scrap"/>
            </label>
          </div>
      </div>


      <!-- Rapport -->
      <div class="column is-4 has-text-centered">
        <!-- Action: Récupèré les données de la CAFAT -->
        <p class="title is-5">Rapport</p>
        <p class="subtitle is-6">Rapport de l'état des déclarations</p>
        <div class="control">
          <router-link class="button is-primary" to="/rapport"><i class="fas fa-arrow-circle-right btn-icon"> </i>Voir le rapport</router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'

const prettyDate = date => new Date(date).toLocaleString().replace(",", " à")

export default {
  name: "Actions",
  data: () => {
    return {
      force_scrap: false,
    }
  },
  computed: {
    ...mapGetters({
      send_disabled: 'dpae/send_disabled',
    }),
    ...mapState({
      date_envoi: state => state.dpae.date_envoi,
      statut_envoi: state => state.dpae.statut_envoi,
      date_scrap: state => state.dpae.date_scrap,
    }),
    scrap_disabled() {
      const delay_passed = new Date() - new Date(this.date_scrap) >= 3600000

      // Disabled if delay not passed AND not force_scrap
      const disabled = !(delay_passed || this.force_scrap)

      return disabled
    },

    message_envoi() {
      // Si les DPAE ont été envoyé
      if (this.send_disabled) {
        return `Envoyé le ${prettyDate(this.date_envoi)}`
      }
      else {
        return "Envoi des déclarations à déclarer"
      }
    },
    message_scrap() {
      if (this.date_scrap) {
        return `Récupèré le ${prettyDate(this.date_scrap)}`
      }
      else {
        return "Récupère les données sur cafat.nc (5min)"
      }
    },
  },
  methods: {
    ...mapActions({
      updateCafat: 'dpae/updateCafat',
      sendData: 'dpae/sendData',
    }),
    _updateCafat() {
      if (!this.scrap_disabled) {
        this.updateCafat(this.force_scrap)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
