/* eslint-disable */

export default [
  {
    type: "email",
    label: "Identifiant",
    name: "email",
    validation: "^required|email",
    validationName: "L'email"
  },
  {
    type: "password",
    label: "Mot de passe",
    name: "password",
    validation: "^required",
    validationName: "Le mot de passe",
    autocomplete: "new-password",
  },
  {
    type: "password",
    label: "Confirmer le mot de passe",
    name: "password_confirm",
    validation: "^required|confirm",
    validationName: "Le mot de passe",
    autocomplete: "new-password",
  },
]
