<template lang="html">
  <div :data-type="context.type">

    <div class="field-password field has-addons">

      <div class="control has-icons-left is-expanded">
          <input class="input" :type="showPassword ? 'text' : 'password'" v-model="context.model"
          @blur="context.blurHandler" :autocomplete="autocomplete"/>

          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
      </div>

      <div class="control" @click="showPassword = !showPassword">
        <span class="button">
          <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
        </span>
      </div>

    </div>
  </div>

</template>

<script>
const iconLookup = {
  password: 'fa-lock',
}
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => {
    return {
      iconLookup,
      showPassword: false,
    }
  },
  computed: {
    autocomplete () {
      if (this.context.attributes.autocomplete) {
        return this.context.attributes.autocomplete
      }
      else {
        return "on"
      }
    }
  }


}
</script>

<style lang="scss" scoped>
  .field-password {
    margin-bottom: 0.75rem;
  }
</style>
