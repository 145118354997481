<template lang="html">
  <div class="section is-medium columns is-centered">

        <div class="columns is-widescreen column is-11">
          <!-- Left Block Form -->
          <div class="column is-8 box">
            <p class="title is-3 has-text-centered">Agence</p>

            <!-- 1st Row -->
            <div class="columns">

              <!-- FormAgence -->
              <div class="column">
                <FormAgence :formData="formData"/>
              </div>

              <!-- Form Cafat -->
              <div class="column">
                <FormCafat/>

                <div class="columns is-centered section">
                  <div class="column is-12">
                    <p class="title is-4 has-text-centered">Comptes Cotisants</p>

                    <table class="table">
                      <thead>
                        <tr>
                          <th>Abbréviation</th>
                          <th>Suffix</th>
                          <th>Activité</th>
                          <th>A Déclarer?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="cc in comptes_cotisants" :key="cc.id">
                          <td>{{cc.abbreviation}}</td>
                          <td>{{cc.suffix_cotisant}}</td>
                          <td>{{cc.activite_principale}}</td>
                          <td class="has-text-centered">
                            <input type="checkbox" :checked="cc.a_declarer" disabled>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Right Block Informations -->
          <div class="column is-3 is-offset-1 box">
            <p class="title is-3 has-text-centered">Informations</p>

            <div class="content">
              <!-- Donnée Valide -->
              <div class="has-text-centered block">
                <p class="title is-4">Validité des Données</p>

                <p>
                  <i class="fas fa-circle" :class="agence.has_valid_infos ? 'has-text-success' : 'has-text-danger'"></i>
                  <span v-if="agence.has_valid_infos">
                    Données d'agence valide
                  </span>
                  <span v-else>
                    Données d'agence invalide
                  </span>
                </p>
              </div>

              <!-- Extras -->
              <div class="has-text-centered block">
                <p class="title is-4">A faire</p>
                <!-- Mission à annuler -->
                <p>Annulations manuelles: {{n_annulation_manuel}}</p>

                <!-- Erreurs d'uploads -->
                <p>
                  Erreurs Intérimaires: {{n_errors_interimaires}}
                </p>

                <p>
                  Erreurs Missions: {{n_errors_missions}}
                </p>
              </div>


              <!-- USERS -->
              <div class="has-text-centered block">
                <p class="title is-4">Utilisateurs</p>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Actif</th>
                      <th>Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in agence_users" :key="user.id">
                      <td>{{user.email}}</td>
                      <td class="has-text-centered">
                        <input type="checkbox" v-model="user.is_active" disabled>
                      </td>

                      <td class="has-text-centered">
                        <input type="checkbox" :checked="user.is_staff" v-model="user.is_staff" disabled>
                      </td>

                    </tr>
                  </tbody>
                </table>

                <button type="button" class="button is-primary is-small" disabled>Mettre à jour les droits</button>


              </div>

            </div>

          </div>
        </div>


      </div>




</template>

<script>
import FormAgence from "@/components/agence/form_agence"
import FormCafat from "@/components/agence/form_cafat"

import {
  mapState,
  mapActions
} from "vuex"

const logError = function(error) {
  if (error.response) {
    console.log(error.response.data)
  } else if (error.message) {
    console.log(JSON.stringify(error.message))
  } else {
    console.log(JSON.stringify(error))
  }
}

export default {
  name: "EspaceAgence",
  components: {
    FormAgence,
    FormCafat,
  },
  data: () => {
    return {
      formData: {},
      //credentials_status: "init",
    }
  },
  beforeMount() {
    this.credentials_status = "init"

    this.$store.dispatch('agence/getAgenceData')
      .then(agence => {
        this.formData = agence
        if (this.formData.cafat_valid) {
          this.credentials_status = "success"
        }
      })
      .catch(error => {
        this.formData = this.agence;
        logError(error)
      })
  },

  methods: {
    ...mapActions({
      submit: "agence/submit",
    }),

    reset_credentials() {
      this.formData.cafat_login = ""
      this.formData.cafat_password = ""
      this.formData.cafat_valid = false
      this.credentials_status = "init"
    },

    prettify_date(json_date) {
      const date = new Date(json_date)
      return `le ${date.toLocaleDateString('fr')} à ${date.toLocaleTimeString('fr')}`
    }
  },
  computed: {
    ...mapState({
      agence: state => state.agence.agence,
      agence_users: state => state.agence.agence_users,
      agence_id: state => state.agence.agence_id,
      comptes_cotisants: state => state.agence.comptes_cotisants,
    }),

    credentials_status: {
      get() { return this.$store.state.agence.credentials_status },
      set(value) { this.$store.commit('agence/setCredentialStatus', value)}
    },

    n_annulation_manuel() {
      return this.$store.state.dpae.stats.statuts.a_annuler.extras.annulation_manuel
    },
    has_errors() {
      return this.n_errors_interimaires + this.n_errors_missions > 0
    },
    n_errors_interimaires() {
      return this.$store.state.dpae.errors.interimaires.length
    },
    n_errors_missions() {
      return this.$store.state.dpae.errors.missions.length
    },
    last_cafat_update() {
      return this.prettify_date(this.agence.last_cafat_update)
    },
    last_caltt_upload() {
      return this.prettify_date(this.agence.last_caltt_upload)
    },
  }
}
</script>

<style lang="scss" scoped>
$box-padding: 50px;
.box {
    padding-bottom: $box-padding;

}

.checkbox {
    user-select: none;
}

.submit {
    margin: 50px 0 25px;
}

.section .box {
    padding-top: 50px;
}
</style>
