<template lang="html">
  <nav class="navbar has-shadow is-fixed-top">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img src="@/assets/brand-img.svg" alt="DPAE: Un utilitaire de déclaration des DPAE... avec un nom super original" width="112" height="28">
      </router-link>
      <div class="navbar-burger">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </div>
    </div>

    <div class="navbar-menu" id="navbarMenu">
      <div class="navbar-start" v-if="!$store.state.auth.isAuthenticated">
        <router-link class="navbar-item" to="/">
          Accueil
        </router-link>

        <router-link class="navbar-item" to="/comment-ca-marche">
          Comment ça marche?
        </router-link>

        <a class="navbar-item" :href="download_url" target="_blank">
          Téléchargement
        </a>

      </div>
      <div class="navbar-start" v-if="$store.state.auth.isAuthenticated">

        <router-link class="navbar-item" to="/">
          Etat des déclarations
        </router-link>

        <router-link class="navbar-item" to="/rapport">
          Rapport
        </router-link>

        <router-link class="navbar-item" to="/comment-ca-marche">
          Comment ça marche?
        </router-link>

        <a class="navbar-item" :href="download_url" target="_blank">
          Téléchargement
        </a>
      </div>
      <div class="navbar-end">

        <div v-if="$store.state.auth.isAuthenticated" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            {{$store.state.auth.user.email}}
          </a>

          <div class="navbar-dropdown">

            <router-link class="navbar-item" to="/agence">
              Espace Agence
            </router-link>

            <hr class="navbar-divider">
            <a class="navbar-item" @click="logout">
              Se déconnecter
            </a>
          </div>
        </div>


        <div class="navbar-item" v-else>
          <router-link class="button" to="/">S'identifier</router-link>
        </div>

      </div>
    </div>

  </nav>
</template>

<script>
export default {
  data: () => {
    return {
      download_url: "https://mega.nz/file/88t3BY7D#gV2ByppPkJc_1syJvzTvGWNQuiz5VbIQ1cL3jl8rfnc",
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    },
  }
}
</script>

<style lang="css" scoped>
</style>
