import axios from 'axios'
import router from '@/router'
import localforage from 'localforage'

const logError = function (error) {
  if (error.response) {
    console.log(error.response.data)
  } else if (error.message) {
    console.log(JSON.stringify(error.message))
  } else {
    console.log(JSON.stringify(error))
  }
}

const default_data = {
  agence: {
    id: null,
    name: "",
    email_domain: "",
    send_auto: false,
    rid: "",
    et: "",
    cafat_identifiant: "",
    email_emetteur: "",
    cafat_number: "",
    code_naf: null,
    code_juridique: null,
    cafat_login: "",
    cafat_password: "",
    cafat_valid: false,
    used_refs: [],
    is_scrapping: false,
    has_valid_infos: false,

  },
  agence_users: [],
  agence_id: null,
  comptes_cotisants: [],
  credentials_status: "init",
}

export default {
  state: () => {
    return JSON.parse(JSON.stringify(default_data))
  },
  mutations: {
    reset(state) {
      Objects.entries(default_data).forEach(([key, value]) => {
        state[key] = value
      })
    },

    setCredentialStatus(state, value) {
      state.credentials_status = value
    },

    updateState(state, data) {
      Object.entries(data).forEach(([key, val]) => {
        state[key] = val
      })

      if (state.agence.cafat_valid) {
        state.credentials_status = "success"
      }
    }
  },
  actions: {
    saveState(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        localforage.setItem(key, value)
      })
    },

    getAgenceData({state, commit, dispatch}) {
      /**
      * Get infos from /agence/get/infos/
      * - agence info
      * - agence member info
      */

        return new Promise((resolve, reject) => {
          axios
            .get("/agence/get/infos/")
            .then(response => {
              commit("updateState", response.data)
              dispatch("saveState", response.data)

              resolve(state.agence)
            })
            .catch(error => logError(error))
        })
    },

    submit({state, commit, dispatch}, formData) {

      axios
        .patch(`/agence/${state.agence_id}/`, formData)
        .then(response => {
          commit("updateState", response.data)
          dispatch("saveState", response.data)
        })
        .catch(error => logError(error))
    },



  },
  getters: {
    //...
  }
}
