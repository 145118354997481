<template lang="html">
  <div class="">
    <p class="title">
      Automate
    </p>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
