<template lang="html">
  <!-- CAFAT -->
  <div class="">
    <div class="columns is-centered">
      <div class="column is-10">
        <div class="">

          <p class="title is-4 has-text-centered">Cafat</p>

          <FormulateForm name="cafatForm" v-model="$parent.formData" :schema="schema" @validation="is_valid = !$event.hasErrors"/>

        </div>
      </div>
    </div>

    <!-- Check Credentials -->
    <!-- Credentials status: Init -->
    <div v-if="$parent.credentials_status == 'init'" class="block has-text-centered">
      <button type="button" class="button is-primary" @click="check_form_credentials">
        Vérifier les identifiants
      </button>
    </div>

    <!-- Credentials status: Pending -->
    <div v-else-if="$parent.credentials_status == 'pending'" class="block has-text-centered has-text-info">
      <span class="icon block">
        <i class="fa fa-spinner fa-3x spin"></i>
      </span>
      <p class="has-text-info">
        <span class="icon is-big"> <i class="fa fa-info-circle"></i></span>
        Vérification des identifiants sur le site cafat.nc
      </p>
    </div>

    <!-- Credentials status: Success -->
    <div v-else-if="$parent.credentials_status == 'success'" class="has-text-centered">
      <button type="button" class="button is-success block" @click="$parent.reset_credentials">Changer les identifiants</button>
      <p class="has-text-success">
        <span class="icon is-big"> <i class="fa fa-check"></i></span>
        Les identifiants sont valide
      </p>
    </div>

    <!-- Credentials status: Error -->
    <div v-else-if="$parent.credentials_status == 'error'" class="has-text-centered block">
      <p class="has-text-danger">
        <span class="icon is-big"> <i class="fa fa-exclamation-triangle"></i></span>
        Les identifiants ne sont pas valide
      </p>
      <button type="button" class="button is-danger" @click="$parent.reset_credentials">Changer les identifiants</button>
    </div>

  </div>

</template>

<script>
import axios from "axios"
import schema from "./form_cafat_schema"

export default {
  data: () => {
    return {
      schema: schema,
      is_valid: false,
    }
  },
  methods: {
    check_form_credentials () {
      if (!this.is_valid) {
        this.$formulate.submit("cafatForm")
        return
      }

      let formData = new FormData()

      formData.append('cafat_login', this.$parent.formData.cafat_login)
      formData.append('cafat_password', this.$parent.formData.cafat_password)

      this.$store.commit("agence/setCredentialStatus","pending")

      axios
        .post("/cafat/check_credentials/", formData)
        .then(response => {
          this.$parent.formData.secteurs = response.data.secteurs
          this.$parent.formData.cafat_number = response.data.cafat_number
          this.$parent.secteurs = response.data.secteurs
          this.$parent.formData.cafat_valid = true
          this.$store.commit("agence/setCredentialStatus","success")

          formData.append('cafat_valid', this.$parent.formData.cafat_valid)

          this.$parent.submit(formData)
        })
        .catch(errors => {
          this.$store.commit("agence/setCredentialStatus","error")
          console.log("errors", errors)
          console.log("formData", formData)
        })
    },
  }

}
</script>

<style lang="scss" scoped>
</style>
