<template>
  <div class="DPAE section">
    <div class="box section container">

      <!-- Title -->
      <div class="has-text-centered main-title">
        <p class="title">
          Etat des déclarations <i v-if="!loaded" class="fas fa-spinner spin has-text-info"></i>
        </p>


        <p class="subtitle">
          <span v-if="loaded">DPAE du {{ $store.state.dpae.created_at | prettyDate }}</span>
          <span v-else>Chargement des données en cours</span>
        </p>
      </div>

      <div v-if="loaded">
        <!-- Task Progress Bar -->

        <!-- <TaskProgress v-if="!!task_id"/> -->

        <!-- Actions -->
        <Actions />

        <!-- Stats -->
        <Stats />

        <!-- Search -->
        <Search />

        <!-- List -->
        <List />
      </div>


    </div>
  </div>
</template>

<script>
import Stats from "@/components/dpae/stats"
import Search from "@/components/dpae/search"
import List from "@/components/dpae/list"
import Actions from "@/components/dpae/actions"
import TaskProgress from "@/components/dpae/taskProgress"

import {mapState} from "vuex"

const prettyDate = date => new Date(date).toLocaleString().replace(",", " à")

export default {
  name: 'DPAE',
  components: {
    Stats, Search, List, Actions, TaskProgress
  },
  props: {
  },
  filters: {
    prettyDate
  },

  beforeMount () {
    //console.log("APP.vue beforeMount")
    this.$store.dispatch("dpae/initialize")
  },

  data: () => {
    return {

    }
  },

  computed: {
    ...mapState({
      loaded: state => state.dpae.loaded,
      //task_id: state => state.task.task_id,
    })
  },

}
</script>

<style lang="scss">
.main-title {
  margin-bottom: 75px;
}


</style>
