<template>
<div class="DPAE section">

  <div class="hero columns is-centered">

    <div class="hero-body card column is-10 is-offset-1">
      <div class="card-content">


        <!-- Title -->
        <div class="has-text-centered main-title">
          <p class="title">
            Rapport de Traitement des DPAEs
          </p>
          <p class="subtitle">
            Du {{ $store.state.dpae.metadata.from | prettyDate }} au {{ $store.state.dpae.metadata.to | prettyDate}}
          </p>
        </div>

        <!-- Stats -->

        <div class="has-text-centered">
          <!-- Statut -->
          <p class="title is-3">Statistiques</p>
          <p class="title is-4">Statuts</p>

          <div class="columns is-centered">

            <div v-for="[statut_dpae, subs], idx in Object.entries(stats.statuts)" :key="idx" class="column is-size-5">
              <p :title="statuts_info[statut_dpae].tooltip">
                <i :class="statuts_info[statut_dpae].icon"></i>
                <strong :class="statuts_info[statut_dpae].class"> {{statuts_info[statut_dpae].label}}:</strong>
              </p>
              <p>
                {{subs.value}}/{{sum}}
                -
                <strong>{{percent(subs.value)}}</strong>
              </p>
              <br>

              <div v-for="[statut_extra, value], idx2 in Object.entries(subs.extras)" :key="idx2" class="is-size-6 is-clickable" @click="print(statut_extra)" :title="statuts_info[statut_extra].tooltip">
                <i :class="statuts_info[statut_extra].icon"></i>
                <span class="has-text-weight-semibold">
                  {{statuts_info[statut_extra].label}}
                  <span class="has-text-weight-light">
                    {{value}}/{{subs.value}}
                  </span>
                </span>
                <br>
              </div>
            </div>
          </div>


          <div class="columns is-centered">
            <div class="column">
              <!-- Secteurs -->
              <p class="title is-4">Secteurs</p>
              <div class="columns">
                <div v-for="[secteur, value], idx in Object.entries(stats.secteur)" :key="idx" class="column is-size-5">
                  <p>
                    <strong> {{secteur}}:</strong>
                  </p>
                  <p>
                    {{value}}/{{sum}}
                    -
                    <strong>{{percent(value)}}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Err upload -->

        <div class="section has-text-centered">
          <p class="title is-3">Erreurs d'upload</p>
          <div class="columns">
            <!-- Erreurs dans les intérimaires -->
            <div v-if="errors.interimaires.length > 0" class="has-text-centered column">
              <p class="title is-4">Erreurs interimaires</p>
              <ol>
                <li v-for="interimaire, i in errors.interimaires" :key="i">
                  {{interimaire}}
                </li>
              </ol>
            </div>

            <!-- Erreurs dans les missions -->
            <div v-if="errors.missions.length > 0" class="has-text-centered column">
              <p class="title is-4">Erreurs Missions</p>
              <ol>
                <li v-for="mission, i in errors.missions" :key="i">{{mission}}</li>
              </ol>
            </div>
          </div>
        </div>

        <div class="section">
          <!-- Declarations -->
          <p class="title is-3 has-text-centered">Déclarations</p>
          <!-- Annulation Manuel -->
          <div v-if="annulation_manuel.length > 0" class="section">
            <p class="title is-4 has-text-centered">
              {{annulation_manuel.length}} Annulations Manuel
              <i :class="statuts_info['annulation_manuel'].icon" :title="statuts_info['annulation_manuel'].label"></i>
            </p>

            <BaseList :data="annulation_manuel" :cols="columns"/>
          </div>

          <!-- A Declarer -->
          <div v-if="a_declarer.length > 0" class="section">
            <p class="title is-4 has-text-centered">
              {{a_declarer.length}} Déclarations À Déclarer
              <i :class="statuts_info['a_declarer'].icon" :title="statuts_info['a_declarer'].label"></i>
            </p>
            <BaseList :data="a_declarer" :cols="columns" />
          </div>

          <!-- STAND BY -->
          <div v-if="standby.length > 0" class="section">
            <p class="title is-4 has-text-centered">
              {{standby.length}} Déclarations en Attente
              <i :class="statuts_info['en_attente'].icon" :title="statuts_info['en_attente'].label"></i>
            </p>
            <BaseList :data="standby" :cols="columns"/>
          </div>
        </div>

        <!-- MISSIONS OUVERTENT -->
        <div class="section">
          <p class="title is-3 has-text-centered">
            {{missions_ouvertent.length}} Missions CalTT Ouvertent
          </p>
          <BaseList :data="missions_ouvertent" :options="missions_ouvertent_options" :cols="missions_ouvertent_columns"/>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import {mapState} from "vuex"
import statuts_info from "@/components/dpae/statuts_info"
import BaseList from "@/components/BaseList"

export default {
  name: 'Rapport',
  components: {
    BaseList,
  },
  props: {

  },
  beforeMount () {
    //console.log("APP.vue beforeMount")
    this.$store.dispatch("dpae/initialize")
  },
  filters: {
    prettyDate: date => date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4)
  },
  data: () => {
    return {
      statuts_info,
      columns : ["secteur", "cafat", "nom", "decl_type", "mission", "date", "statut_dpae", "statut_extra"],
      missions_ouvertent_options: {
        highlight:
        {
            condition: (el) => el.is_danger,
            class: 'has-background-danger-light has-text-danger',
        }
      },
      missions_ouvertent_columns: ["mission", "cafat", "nom", "EMB", "RUP", "permanent"],
    }
  },
  methods: {
    percent(datum) {
      return `${(datum / this.sum * 100).toFixed(2)}%`
    },

    sort_data(data) {
      const declTypeOrder = {EMB: "1", RUP: "2", ANN: "3"}

      return data.sort( (line1, line2) => {
        // Sort by: Cafat, Date, DeclType
        const cafatSort = line1.cafat - line2.cafat
        const dateSort = line1._date_ - line2._date_

        const declTypeSort = declTypeOrder[line1.decl_type].localeCompare(declTypeOrder[line2.decl_type])

        return cafatSort || dateSort || declTypeSort
      })
    },
  },
  computed: {
    ...mapState({
      stats: state => state.dpae.stats,
      errors: state => state.dpae.errors,
      raw_data: state => state.dpae.data,
    }),

    annulation_manuel() {
      var data = this.raw_data
      data = data.filter(el => el.statut_extra === 'annulation_manuel')
      return this.sort_data(data)
    },

    a_declarer() {

      var data = this.raw_data.filter(el => ['a_declarer', 'a_annuler'].includes(el.statut_dpae) && el.statut_extra !== 'annulation_manuel')
      // console.log("A Declarer:", data.length, this.raw_data.length)
      return this.sort_data(data)
    },

    standby() {
      var data = this.raw_data.filter(el => el.statut_dpae === 'en_attente')
      return this.sort_data(data)
    },

    missions_ouvertent() {
      var data = this.errors.missions_ouvertent

      const has_danger = function (line) {
        var [d,m,y] = line.RUP.split("/")
        const date_rup = new Date([y,m,d])
        var is_danger = new Date() - date_rup > 86400000 // 2 days or older = danger

        return {
          ...line,
          is_danger
        }
      }

      data = data.map((line) => has_danger(line))

      data = data.sort((l1, l2) => {
        l1.permanent.localeCompare(l2.permanent) || l1.mission.localeCompare(l2.mission)
      })

      // console.log(data)
      return data
    },


    sum() {
      var sum = 0
      const sumObj = this.$store.state.dpae.stats.statut_dpae
      Object.values(sumObj).forEach((value) => {
        sum += value
      })
      return sum
    },
  }
}
</script>

<style lang="scss">
.main-title {
    margin-bottom: 75px;
}

.origin {
    text-transform: uppercase;
}
</style>
