<template lang="html">
  <div class="search">

    <!-- Search -->
    <div class="hero">
      <div class="hero-body">

        <!-- SearchBar -->
        <div class="field has-addons searchBar">
          <div class="control has-icons-left is-expanded">
            <input type="text" class="input is-rounded searchInput" v-model.lazy="search_query"/>
            <span class="icon is-left is-small">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <div class="control">
            <a class="button is-rounded" @click="toggleShowOptions">
              <i class="fa fa-cogs"></i>
            </a>
          </div>
        </div>

        <!-- SearchOptions -->
        <div class="searchOptions">
          <div v-if="showOptions">
            <div class="box is-radiusless">

              <!-- Title -->
              <p class="title">Filtre</p>
              <a class="delete is-medium"  @click="toggleShowOptions"></a>

              <!-- Body -->
              <div class="content">

                <!-- Periode -->
                <div class="block">
                  <p class="title is-5">Par Période :</p>
                  <div class="field is-grouped has-text-centered">
                    <div class=" field is-horizontal">
                      <p class="datelabel mr-3">Du</p>
                      <div class="control">
                        <input type="date" class="input is-small" v-model="options.filter.from">
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <p class="datelabel mx-3">Au</p>
                      <div class="control">
                        <input type="date" class="input is-small" v-model="options.filter.to">
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Origin -->
                <div class="block">
                  <p class="title is-5">Par Origine :</p>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field is-narrow">
                        <div class="control ckb-grp">
                          <label class="checkbox">
                            <input type="checkbox" v-model="options.filter.origin" value="cafat">
                            CAFAT
                          </label>
                          <label class="checkbox">
                            <input type="checkbox" v-model="options.filter.origin" value="caltt">
                            CALTT
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Secteur -->
                <div class="block">
                  <p class="title is-5">Par Secteur :</p>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field is-narrow">
                        <div class="control ckb-grp">
                          <label class="checkbox" v-for="secteur,i in metadata.secteurs" :key="i">
                            <input type="checkbox" v-model="options.filter.secteurs" :value="secteur">
                            {{secteur}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Statut -->
                <div class="block">
                  <p class="title is-5">Par Statut :</p>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field">
                        <div class="control ckb-grp columns">
                          <div class="column is-one-quarter" v-for="statut,i in statuts_list" :key="i">

                            <!-- Statut DPAE -->
                            <label class="checkbox is-size-5">
                              <input type="checkbox" v-model="options.filter.statut_dpae" :value="statut.value">
                              {{statut.label}}
                            </label>

                            <!-- Statut Extra -->
                            <div class="inner-ckb is-size-6" v-for="extra, idx in statut.extras" :key="idx">
                              <label class="checkbox" :disabled="!options.filter.statut_dpae.includes(statut.value)">
                                <input
                                  class="is-small" type="checkbox"
                                  v-model="options.filter.statut_extra" :value="extra.value"
                                  :disabled="!options.filter.statut_dpae.includes(statut.value)"
                                >
                                {{extra.label}}
                              </label>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Element par page -->
                <div class="block">
                  <p class="title is-5">Nombre d'élément par page :</p>
                  <div class="field">
                    <div class="control select is-small">
                      <select v-model="options.elpp" >
                        <option value="10">10</option>
                        <option value="25" selected>25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- Action: Quit & Reset -->
                <div class="block has-text-centered">
                  <div class="field is-grouped is-horizontal is-grouped-centered">
                    <div class="control">
                      <a class="button is-outlined" @click="toggleShowOptions">Fermer</a>
                    </div>
                    <div class="control">
                      <a class="button is-danger" @click="resetOptions">Reset les Options</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import utils from '@/utils'
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => {
    return {
      showOptions: false,
      statuts_list: [
        {
          label: 'OK',
          value: 'ok',
          extras: [
            {label: 'Normal', value: 'none_ok'},
            {label: 'Chevauchement CAFAT', value: 'chevauchement_cafat'},
            {label: 'Chevauchement CALTT', value: 'chevauchement_caltt'},
          ]
        },
        {
          label: 'A Déclarer',
          value: 'a_declarer',
          extras: [
            {label: 'Normal', value: 'none_a_declarer'},
            {label: 'Patch CAFAT', value: 'patch_cafat'},
            {label: 'Patch CALTT', value: 'patch_caltt'},
          ]
        },
        {
          label: 'A Annuler',
          value: 'a_annuler',
          extras: [
            {label: 'Annulation AUTO', value: 'annulation_auto'},
            {label: 'Annulation Manuelle', value: 'annulation_manuel'},
          ]
        },
        {
          label: 'Stand-by',
          value: 'en_attente',
          extras: [
            {label: "Attente d'annulation", value: 'attente_annulation'},
            {label: 'Multiple missions ouvertent', value: 'multiple_open'},
          ]
        },
      ],
      options: {},
    }
  },
  mounted () {
    this.resetOptions()
  },
  methods: {
    ...mapMutations({
        _resetOptions: 'dpae/resetOptions',
        setOptions: 'dpae/setOptions',
    }),

    resetOptions() {
      this._resetOptions()
      this.options = JSON.parse(JSON.stringify(this.$store.state.dpae.options))
    },


    toggleShowOptions() {
      this.showOptions = !this.showOptions
      //console.log(this.showOptions)
      if (!this.showOptions) {
        //console.log("Updating options with", this.options)
        this.setOptions(this.options)
      }
    },

  },
  computed: {
    ...mapState({
      // options: (state) => state.dpae.options,
      metadata: (state) => state.dpae.metadata,
    }),
    search_query: {
      get() { return this.$store.state.dpae.options.search },
      set(value) { this.$store.commit("dpae/setSearchQuery", value) },
    }
  }
}
</script>

<style lang="scss" scoped>

.searchInput {
  &:focus {
    box-shadow: none;
    border-color: inherit;
  }
}

.searchOptions {
  width: 80%;
  position: absolute;
  left: 50%;
  transform:translateX(-50%) translateY(-12px);
  z-index: 9999;
}
.ckb-grp {
  .checkbox{
      margin-right: 10px;
  }
}

.field {
  margin-top: 0px;
}

.datelabel {
  position: relative;
  top: 3px;
}

.delete {
  position: absolute;
  top: 20px;
  right: 20px;
}

.inner-ckb {
  position: relative;
  left: 15px;
}
</style>
