<template lang="html">
  <div class="section">
    <div class="columns is-vcentered is-centered">
      <div class="column is-6 has-text-centered">
        <p>
          <span class="is-pulled-left">
          <strong>
            {{title}}:
          </strong>
          {{description}}
          </span>
          <span class="is-pulled-right">
            {{progress.percent}}
          </span>
        </p>
        <progress class="mb-0 progress is-small" :class="(status == 'SUCCESS' ? 'is-success': (status == 'FAILURE' ? 'is-danger' : 'is-primary'))" :value="progress.current" :max="progress.total"></progress>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TaskProgress',
  data: () => {
    return {
    }
  },
  mounted () {
    //this.$store.dispatch("task/endTaskProgress")
  },
  props: {
  },
  computed: {
    ...mapState({
      task_id: state => state.task.task_id,
      title: state => state.task.title,
      description: state => state.task.description,
      status: state => state.task.status,
      complete: state => state.task.complete,
      success: state => state.task.success,
      progress: state => state.task.progress,
    }),

  },
  methods: {

  }
}

</script>

<style lang="css" scoped>
.section {
  padding-top: 0;
  transform: translateY(-20px);
}


</style>
