
export default {
  state: () => {
    return {
      type: 'primary',
      message: '',
      html: '',
      show: false,
      duration: 5000,
    }
  },
  mutations: {
    show(state, payload) {
      state.duration = payload.duration
      state.message = payload.message
      state.html = payload.html
      state.type = payload.type
      state.show = true
    },

    hide(state) {
      state.show = false
    }
  },
  actions: {
    async notify ({state, commit}, payload) {
      // Validate the payload here
      var payload_keys = [
        "duration", "message", "html", "message", "type"
      ]

      payload_keys.forEach(key => {
        if (!payload[key]) {
          payload[key] = state[key]
        }
      })

      // show the notification
      commit("show", payload)

      // set timeout
      await new Promise(r => setTimeout(r, payload.duration))

      // hide the notification
      commit("hide")
    }

  },
  getters: {
    //...
  }
}
