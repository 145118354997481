import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"

// Views
import Home from '@/views/Home.vue'
import Dpae from '@/views/DPAE'
import HowTo from '@/views/HowTo'
import Automate from '@/views/Automate'
import TestView from '@/views/Test'
import RapportView from '@/views/Rapport'
import EspaceAgence from '@/views/Espace/agence'
import Activation from '@/views/Activation'
import Verify from '@/views/Verify'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'Authentication',
    component: Home
  },
  {
    path: '/comment-ca-marche',
    name: 'HowTo',
    component: HowTo,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/',
    name: 'Accueil',
    component: Dpae,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/agence',
    name: 'EspaceAgence',
    component: EspaceAgence,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/rapport',
    name: 'Rapport',
    component: RapportView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/activate/:uid/:token',
    name: 'Activation',
    component: Activation,
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // The page requires to be logged in
  if (to.matched.some(record => record.meta.requireLogin)) {
    console.log("Authenticated?", store.state.auth.isAuthenticated)
    console.log("Agence has valid infos?", store.state.agence.agence.has_valid_infos)

    console.log("Page requires login")
    // User is logged in, continue
    if (store.state.auth.isAuthenticated) {
      console.log("User is authenticated")

      // if Agence needs to be updated:
      // Redirect vers Espace Agence

      if (!store.state.agence.agence.has_valid_infos && to.name !== "EspaceAgence") {
        console.log("Redirigé vers espace Agence")
        next({path: '/agence'})
      }
      else {
        next()
      }
    }
    // User is not logged in, return to Home
    else {
      console.log("User is NOT authenticated")
      next({name: 'Authentication'})
    }
    // The page doesn't require to be logged in
  } else {
    next()
  }
})

export default router
