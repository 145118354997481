<!-- Use preprocessors via the lang attribute! e.g. <template lang="pug"> -->
<template>
<div id="app">
  <div class="columns is-centered">

    <div class="card column is-3">
      <div class="card-content">
        <div class="hero">
          <div class="hero-body  hero-img  has-text-centered">
            <img src="@/assets/main-img.svg" alt="">
          </div>
        </div>

        <transition name="fade-in">
          <div v-if="state == 'login'" class="column">
            <Login @change-view="changeState"/>
          </div>

          <div v-if="state == 'signup'" class="column">
            <Signup @change-view="changeState"/>
          </div>
        </transition>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Login from "@/views/Home/login"
import Signup from "@/views/Home/signup"

export default {
  components : {
    Login, Signup
  },
  data: () => {
    return {
      state: 'login',
    }
  },
  methods: {
    changeState () {
      this.state = this.state == 'login' ? 'signup' : 'login'
    }
  }
}
</script>

<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->
<style lang="scss" scoped>
.card {
  margin-top: 50px;
}

.hero-body {
  padding-bottom: 0px;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-in-enter-active {
  transition: all .3s ease;
}
.fade-in-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-in-enter, .fade-in-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
